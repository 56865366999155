export const environment = {
    production: true,
    hmr: false,
    appMetadata: {
        appDomain: {
            title: 'wm.daptech.vn',
            owner: 'wm.daptech.vn',
            logo: 'assets/images/logo-dapfood-x-w.png',
        }
    },
    apiDomain: {
        gateway: 'https://wmapi.friends.vn',
        authenticationEndpoint: 'https://wmaccount.friends.vn',
        authorizationEndpoint: 'https://wmapi.friends.vn/authorization.api',
        fileEndpoint: 'https://wmapi.friends.vn/file.api',
        notificationEndpoint: 'https://wmnotification.friends.vn',
        dapFoodEndPoint: 'https://wmapi.friends.vn/dapfood.api',
        linkEndPoint: 'https://wmapi.friends.vn/weblink',
    },
    clientDomain: {
        appDomain: 'https://wm.daptech.vn',
        adminDomain: 'https://wmadmin.friends.vn',
        idPhanhe: 1,
    },
    authenticationSettings: {
        clientId: 'dapfood',
        issuer: 'https://wmaccount.friends.vn'
    },
    systemLogSetting: {
        enabled: false
    },
    signalr: {
        clientKey: 'dapfood',
        endpoint: '',
        linkDownloadClientApp: ''
    },
    signalrConfig: {
        hub: {
            notification: 'NotificationHub'
        },
        action: {
            notificationCreated: '',
            viewUpdated: ''
        }
    },
    firebaseConfig: {
        apiKey: 'AIzaSyBSkn5jUqD0N4PiiDEhsWyfPIws6TyNUho',
        authDomain: 'dapfood-firebase.firebaseapp.com',
        databaseURL: 'https://dapfood-firebase.firebaseio.com',
        projectId: 'dapfood-firebase',
        storageBucket: 'dapfood-firebase.appspot.com',
        messagingSenderId: '1088787441716',
        appId: '1:1088787441716:web:329c0dfd4356195f98c261',
        measurementId: 'G-RVEWV87V1P'
    }
}